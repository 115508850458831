import React from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const defaultRespons = {
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 4,
    slidesToSlide: 2,
    partialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 60
  }
}

const InfraCarousel = ({ children, responsive = defaultRespons }) => (
  <div className="carousel">
    <Carousel
      containerClass="carousel__content"
      draggable={false}
      showDots={true}
      renderDotsOutside={true}
      dotListClass="carousel__dot-list"
      responsive={responsive}
      ssr={true}
      keyBoardControl={true}
      minimumTouchDrag={50}
      removeArrowOnDeviceType="mobile"
      transitionDuration={100}
      slidesToSlide={2}
      partialVisible
    >
      {children}
    </Carousel>
  </div>
)

export default InfraCarousel